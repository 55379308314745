"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var ClickAwayListener = function (_a) {
    var onClickAway = _a.onClickAway, _b = _a.mouseEvent, mouseEvent = _b === void 0 ? 'click' : _b, _c = _a.touchEvent, touchEvent = _c === void 0 ? 'touchend' : _c, children = _a.children;
    var node = react_1.useRef(null);
    react_1.useEffect(function () {
        var handleEvents = function (event) {
            if (node.current && node.current.contains(event.target)) {
                return;
            }
            onClickAway();
        };
        document.addEventListener(mouseEvent, handleEvents);
        document.addEventListener(touchEvent, handleEvents);
        return function () {
            document.removeEventListener(mouseEvent, handleEvents);
            document.removeEventListener(touchEvent, handleEvents);
        };
    });
    return react_1.default.createElement("div", { ref: node }, children);
};
exports.default = ClickAwayListener;
